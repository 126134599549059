import React from 'react'
import { Link } from 'gatsby'
import logo from '../../../static/img/logo.png';

export const NavbarTemplate = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsing: false,
      collapsed: true,
      sticky: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll.bind(this));
  }

  handleScroll() {
    const scroll = document.documentElement.scrollTop || document.body.scrollTop;
    
    if (scroll < 60) {
      this.setState({sticky: false});
    } else {
      this.setState({sticky: true});
    }
  }

  toggleHamburger = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  render() {
    return (
      <header className="header-area">
        <div className="header-top">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="header-top-item d-flex justify-content-between">
                            <div className="header-left-side brand">
                                <p>{this.props.companyName}</p>
                            </div>
                            <div className="header-right-social d-flex justify-content-end align-items-center hours">
                                <span><i className="far fa-clock"></i>{" "}{this.props.open}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header-nav">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={`navigation`}>
                            <nav className="navbar navbar-expand-lg navbar-light ">
                                <Link className="navbar-brand" to={`/`}><img className="brand-logo-img" src={logo} alt=""/></Link>
                                <button onClick={this.toggleHamburger} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>

                                <div className={`collapse navbar-collapse sub-menu-bar ${this.state.collapsed ? '' : 'show'}`} id="navbarSupportedContent">
                                    <ul className="navbar-nav ml-3">
                                        {
                                            this.props.items.map((item, itemIndex) => {
                                                const hasChildren = item.children && item.children.length > 0;

                                                return (
                                                    <li key={itemIndex} className={`nav-item ${hasChildren ? 'dropdown' : ''}`}>
                                                        {
                                                            item.href.startsWith('http') 
                                                                ? (
                                                                    <a className={`nav-link ${hasChildren ? 'dropdown-toggle' : ''}`} 
                                                                        href={item.href} 
                                                                        data-toggle="dropdown"
                                                                    >
                                                                        {item.label}
                                                                    </a>
                                                                ) : (
                                                                    <Link
                                                                        to={`/${item.href}`}
                                                                        className={`nav-link ${hasChildren ? 'dropdown-toggle' : ''}`}
                                                                        data-toggle="dropdown"
                                                                    >
                                                                        {item.label}
                                                                    </Link>
                                                                )
                                                        }
                                                        {
                                                            hasChildren ?
                                                                (
                                                                    <ul className="sub-menu dropdown-menu">
                                                                        {
                                                                            item.children.map((child, childIndex) => (
                                                                                <li key={childIndex}>
                                                                                    {
                                                                                        child.href.startsWith('http') 
                                                                                            ? <a href={`${child.href}`}>{child.label}</a>
                                                                                            : <Link to={`/${child.href}`}>{child.label}</Link>
                                                                                    }
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                ) : 
                                                                <></>
                                                        }
                                                    </li>
                                                ) 
                                            })
                                        }
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    )
  }
}

const Navbar = (props) => {
    return <NavbarTemplate
      items={props.menu.menuItems}
      companyName={props.menu.companyName}
      open={props.menu.open}
    />
};

export default Navbar;
