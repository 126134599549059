import React from 'react'
import {Helmet} from 'react-helmet'
import Footer from './Footer'
import NavbarTemplate from './Navbar'
import './all.sass'
import {withPrefix} from 'gatsby'

const TemplateWrapper = ({ children, menu, footer, title, description }) => {
  return (
    <div>
      <Helmet>
        <html lang="pl" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link rel="apple-touch-icon" sizes="57x57" href={`${withPrefix('/')}icons/apple-icon-57x57.png`}/>
        <link rel="apple-touch-icon" sizes="60x60" href={`${withPrefix('/')}icons/apple-icon-60x60.png`}/>
        <link rel="apple-touch-icon" sizes="72x72" href={`${withPrefix('/')}icons/apple-icon-72x72.png`}/>
        <link rel="apple-touch-icon" sizes="76x76" href={`${withPrefix('/')}icons/apple-icon-76x76.png`}/>
        <link rel="apple-touch-icon" sizes="114x114" href={`${withPrefix('/')}icons/apple-icon-114x114.png`}/>
        <link rel="apple-touch-icon" sizes="120x120" href={`${withPrefix('/')}icons/apple-icon-120x120.png`}/>
        <link rel="apple-touch-icon" sizes="144x144" href={`${withPrefix('/')}icons/apple-icon-144x144.png`}/>
        <link rel="apple-touch-icon" sizes="152x152" href={`${withPrefix('/')}icons/apple-icon-152x152.png`}/>
        <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}icons/apple-icon-180x180.png`}/>
        <link rel="icon" type="image/png" sizes="192x192" href={`${withPrefix('/')}icons/android-icon-192x192.png`}/>
        <link rel="icon" type="image/png" sizes="32x32" href={`${withPrefix('/')}icons/favicon-32x32.png`}/>
        <link rel="icon" type="image/png" sizes="96x96" href={`${withPrefix('/')}icons/favicon-96x96.png`}/>
        <link rel="icon" type="image/png" sizes="16x16" href={`${withPrefix('/')}icons/favicon-16x16.png`}/>
        <link rel="manifest" href={`${withPrefix('/')}icons/manifest.json`}/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="msapplication-TileImage" content={`${withPrefix('/')}icons/ms-icon-144x144.png`}/>
        <meta name="theme-color" content="#ffffff"/>

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/img_left.png`}
        />
      </Helmet>
      <NavbarTemplate menu={menu} />
      <div>{children}</div>
      <Footer footer={footer} />
    </div>
  )
}

export default TemplateWrapper
