import React from 'react'
import TemplateWrapper from "@trzyrybki/shared-ui/components/Layout";
import menu from "../menu.json";
import footer from "../footer.json";
import metadata from "../metadata.json";

export const LayoutTemplate = ({children, menu, footer, title, description}) => {
  return (
    <TemplateWrapper
      footer={footer}
      menu={menu}
      title={title}
      description={description}>
      {children}
    </TemplateWrapper>
  )
}

const Layout = ({children}) => {
  return (
    <LayoutTemplate
      footer={footer}
      menu={menu}
      title={metadata.title}
      description={metadata.description}>
      {children}
    </LayoutTemplate>
  )
}
export default Layout
