import React from 'react'

import logo from '../../../static/img/logo.png'

export const FooterTemplate = ({
   header,
   addressLine1,
   addressLine2,
   email,
   phone,
   companyNameLine1,
   companyNameLine2,
   companyNameLine3,
   mapQuery,
   mapZoom,
   facebookUrl,
   facebookText
 }) => {
  return (
      <footer className="footer-bg pt-80 pb-80">
          <div className="footer-area">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-6 col-md-6">
                          <div className="row">
                              <div className="col-12">
                                <h4>{header}</h4>
                              </div>
                          </div>
                          <div className="row pt-20">
                              <div className="col-lg-6 col-md-6">
                                  <div className="single-footer-wedget">
                                      <div className="footer-contact-area">
                                          <span><i className="fas fa-map-marker-alt"></i></span>
                                          <span>{addressLine1}<br />{addressLine2}</span>
                                      </div>

                                      <div className="footer-contact-area">
                                          <span><i className="fas fa-envelope-open-text"></i></span>
                                          <span><a href={`mailto:${email}`}>{email}</a></span>
                                      </div>

                                      <div className="footer-contact-area">
                                          <span><i className="fas fa-phone-volume"></i></span>
                                          <span><a href={`tel:${phone}`}>{phone}</a></span>
                                      </div>

                                    { facebookUrl ?
                                      <div className="footer-contact-area">
                                        <span><i className="fab fa-facebook"></i></span>
                                        <span><a href={facebookUrl}>{facebookText}</a></span>
                                      </div> : <></>
                                    }
                                  </div>
                              </div>
                              <div className="col-lg-6 col-md-6">
                                  <div className="single-footer-wedget">
                                      <div className="single-footer-wedget">
                                          <p>{companyNameLine1}<br />
                                              <strong>{companyNameLine2}</strong> <br />
                                            {companyNameLine3}</p>
                                          <div className="logo footer-logo"><a href="#"><img src={logo} alt="" /></a></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                          <div id="map"></div>
                          <iframe defer width="100%" height="100%" frameBorder="0" style={{ border: '1px gray solid' }} src={`https://www.google.com/maps/embed/v1/place?zoom=${mapZoom}&q=${mapQuery}&key=AIzaSyDKt5qlBZJ-KgsavOkbSQHh57vybKRwbnI`}></iframe>
                      </div>
                  </div>
                  <div className="row" style={{ textAlign: "center"}}>
                    <div className="col-12 footer-credits pt-40">
                      <p>Realizacja: <a href="https://toporowicz.it">toporowicz.it</a></p>
                      <p>Projekt i wykonanie grafik ryb: Joanna Kinder</p>
                    </div>
                  </div>
              </div>
          </div>
      </footer>
  )
}

const Footer = (props) => {
  return <FooterTemplate {...props.footer} />
};

export default Footer
